export const postingColumns = [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "attributes.household.attributes.designation",
    title: "Haushalt",
    sortable: true,
    options: { hrefFunction: (data: any) => `/postings/${data.item.id}` }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.createdAt",
    title: "Erstellungsdatum",
    sortable: true,
    options: { type: 'date' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.active",
    title: "Aktiv",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.comitted",
    title: "Abschlussicher",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "agencies",
    title: "Agenturen",
    sortable: true,
    options: {
      contentFunction: (data: any) => {
        return data.item.relationships.visors.data.length
      }
    }
  },
]